import React from "react"
import { SectionHeadings } from "../helperComponents/helpers"
import GetScore from "./GetScore"
import "./style/cta.scss"
import ScrollAnimation from "react-animate-on-scroll"

const Cta = ({ heading, headingBold, headingRest, subheading, button = '' }) => {
  return (
    <div className="cta-container">
      <ScrollAnimation animateIn="fadeIn" animateOut="fadeOut">
        {(heading && subheading) ? 
          <SectionHeadings
            heading={heading}
            headingBold={headingBold}
            headingRest={headingRest}
            subheading={subheading}
          />
        :
          <SectionHeadings
            heading={"Ready to improve your "}
            headingBold={"organisation security "}
            headingRest={"today?"}
            subheading={"Try Securiwiser for free"}
          />
        }
      </ScrollAnimation>
      <GetScore button={button} />
    </div>
  )
}

export default Cta
