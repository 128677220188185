import React from "react"
import "./style/cta.scss"

const GetScore = ({ button = '' }) => {
    if (button && button.url) {
        return (
            <div className="get-score-button">
                <a className="button" role="button" href={button.url} title={button?.text ?? "Get Free Score"}>{button?.text ?? "Get Free Score"}</a>
            </div>
        );
    }

    return (
        <form action="https://app.securiwiser.com/signup" className="get-score">
            <input placeholder="Enter your website URL" required/>
            <button href="https://app.securiwiser.com/signup">Get Free Score</button>
        </form>
    );
}

export default GetScore
