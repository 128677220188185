import * as React from "react"
import {
    graphql
} from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/layout/seo"
import HeroSection from "../components/heroSectionDark/heroSectionDark"
import "../components/industry/style/style.scss"
import Cta from "../components/cta/cta"
import FeaturesCards from "../components/featuredCards/featuredCards"
import FeaturesGrid from "../components/featuresGrid/featuresGrid"
import Cybercrime from "../components/home/cybercrime"


export const query = graphql`
    query($slug: String!) {
        allFile(
            filter: {sourceInstanceName: {eq: "seo-images"}}
        ) {
            edges {
                node {
                    publicURL
                }
            }
        }
        allFeaturesJson(filter: { slug: { eq: $slug } })
        {
            nodes {
                slug
                title
                description
                button {
                    url
                    text
                }
                image {
                    publicURL
                }
                imageAlt
                videoId
                eBookUrl
                eBookTagline
                metaDescription
                metaTitle
                cta {
                    heading
                    headingBold
                    headingRest
                    subheading
                }
                features {
                    desc
                    image { publicURL }
                    imageAlt
                    title
                    subtitle
                    url
                }
            }
        }
    }
`
export default function FeaturesTemplate({ data, pageContext }) {
    const allData = data?.allFeaturesJson?.nodes[0]
    const featuresData = data?.allFeaturesJson?.nodes[0]?.features
    const featureSlug = allData.slug
    const seoImages = data?.allFile?.edges
    let seoImageUrl = '';
    seoImages.map(image => {
        if  (image.node.publicURL.includes(featureSlug)) {
            seoImageUrl = image.node.publicURL
        }
    })
    return (
        <Layout>
            <SEO
                image={seoImageUrl?.length > 1 ? `https://www.securiwiser.com${seoImageUrl}` : null}
                description={allData?.metaDescription}
                title={allData?.metaTitle}
            />
            <HeroSection
                title={allData.title}
                description={allData.description}
                slug={allData.slug}
                image={allData.image}
                imageAlt={allData.imageAlt}
                button={allData?.button}
                direction="direction-row"
                variant="feature"
            />
            {pageContext.slug === 'ratings' &&
                <FeaturesGrid
                    heading="Cybersecurity scoring made simple with Securiwiser"
                    subheading="Checking over 100+ endpoints"
                />
            }
            <FeaturesCards data={featuresData} slug={pageContext.slug}/>
            <Cybercrime />
            {/* <Ebook eBookUrl="#" eBookTagline={allData.eBookTagline}/> */}
            <Cta/>
        </Layout>
    )
}